import React, { useContext, useEffect, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { constants } from '../../utils/constants'
import { capitalize } from '../../utils/text'

import { hasPermission } from '../../utils/permissions'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import RiskMatrixService from '../../services/RiskMatrixService'
import UserService from '../../services/UserService'

import { getObjectByValue } from '../../utils/objects'

import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form'
import FormField from '../../components/simple/formField/FormField.js'
import Grid from '../../components/simple/grid/Grid.js'
import Layer from '../../components/simple/layer/Layer'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

import { ResponsiveContext } from 'grommet'
import { RiskContext } from './RiskContext'
import { StepContent } from './StepContent'
import { StepFooter } from './StepFooter'

// import { getWidth } from './utils'

export const defaultFormValues = {
  firstName: '',
  lastName: '',
  gender: '',
  nationality: '',
  age: '',
  accommodationOtherNotes: '',
  addictionOtherNotes: '',
  disabilityOtherNotes: '',
  financesbudgetingOtherNotes: '',
  languageOtherNotes: '',
  legalOtherNotes: '',
  mentalOtherNotes: '',
  physicalOtherNotes: '',
  relationshipOtherNotes: ''
}

const apiToken = LocalStorageService.get('apiToken')
const activeOrg = LocalStorageService.get('activeOrg')
const activeUser = LocalStorageService.get('userDetails')

const riskColorLookup = {
  Safeguarding: '#ff0012',
  'High Complexity': '#f16f33',
  'Medium Complexity': '#bc831c',
  'Low Complexity': '#63a345',
  Independent: '#00a9ea',
  'Missing Answers': '#e6a43b',
  Complete: '#dadada'
}

const riskScoreLookup = {
  Safeguarding: 0,
  'High Complexity': 1,
  'Medium Complexity': 2,
  'Low Complexity': 3,
  Independent: 4
}

const riskLabels = ['Safeguarding', 'High Complexity', 'Medium Complexity', 'Low Complexity', 'Independent']

const overallRisk = {
  score: -1
}

const stepAnswers = {
  accommodation: {
    accommodation1: {},
    accommodation2: {},
    accommodation3: {},
    riskScore: -1 // don't think these are used now - moved to state
  },
  addiction: {
    addiction1: {},
    addiction2: {},
    riskScore: -1
  },
  disability: {
    disability1: {},
    disability2: {},
    disability3: {},
    disability4: {},
    riskScore: -1
  },
  finances: {
    finances1: {},
    finances2: {},
    finances3: {},
    finances4: {},
    finances5: {},
    finances6: {},
    riskScore: -1
  },
  language: {
    language1: {},
    language2: {},
    language3: {},
    riskScore: -1
  },
  legal: {
    legal1: {},
    legal2: {},
    legal3: {},
    legal4: {},
    legal5: {},
    legal6: {},
    legal7: {},
    riskScore: -1
  },
  mental: {
    mental1: {},
    mental2: {},
    mental3: {},
    mental4: {},
    riskScore: -1
  },
  physical: {
    physical1: {},
    physical2: {},
    physical3: {},
    riskScore: -1
  },
  relationship: {
    relationship1: {},
    relationship2: {},
    relationship3: {},
    relationship4: {},
    relationship5: {},
    riskScore: -1
  }
}

const stepOneInputs = [
  (setValid) => (
    <>
      <Box direction='row-responsive' gap='small'>
        <FormField
          label='First Name'
          htmlFor='firstName'
          name='firstName'
          required
        >
          <TextInput
            id='firstname'
            name='firstName'
            onChange={() => setValid(true)}
          />
        </FormField>

        <FormField
          label='Last Name'
          htmlFor='lastName'
          name='lastName'
          required
        >
          <TextInput
            id='lastname'
            name='lastName'
            onChange={() => setValid(true)}
          />
        </FormField>
      </Box>

      <Box direction='row-responsive' gap='small'>
        <FormField
          label='Gender'
          htmlFor='gender'
          name='gender'
          required
        >
          <Select
            name='gender'
            labelKey='label'
            options={constants.gender}
            valueKey={{ key: 'value', reduce: true }}
          />
        </FormField>

        <FormField
          label='Nationality'
          htmlFor='nationality'
          name='nationality'
          required
        >
          <Select
            name='nationality'
            labelKey='label'
            options={constants.countries}
            valueKey={{ key: 'value', reduce: true }}
          />
        </FormField>
      </Box>

      <Box direction='row-responsive' gap='small'>
        <FormField
          label='Age'
          htmlFor='age'
          name='age'
          required
        >
          <TextInput
            id='age'
            name='age'
            onChange={() => setValid(true)}
            type='number'
          />
        </FormField>
      </Box>
    </>)
]

// Accommodation 1 of 3
const stepTwoOptions = [
  {
    value: 'accommodation1_Homelessness_with_a_child',
    label: 'Homelessness (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'accommodation1_Homelessness',
    label: 'Homelessness',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation1_Living_with_risky_people',
    label: 'Living with risky people',
    risk: 'Medium Complexity'
  },
  {
    value: 'accommodation1_Temporary_accommodation_with_council',
    label: 'Temporary Accommodation with Council',
    risk: 'Low Complexity'
  },
  {
    value: 'accommodation1_Permanent_accommodation_council_or_otherwise',
    label: 'Permanent accommodation (council or otherwise)',
    risk: 'Independent'
  }
]

// Set the answers for the section
const updateAnswer = (value, options, section, subsection) => {
  const riskLevel = getObjectByValue(options, 'value', value)
  stepAnswers[section][subsection].risk = riskLevel

  // updateOverallRisk()
}

// Accommodation 2 of 3
const stepThreeOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'accommodation2_Imminent_Risk_of_eviction_destitution',
    label: 'Imminent Risk of eviction/destitution',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation2_Temporary_accommodation_at_B&B',
    label: 'Temporary accommodation at B&B',
    risk: 'Medium Complexity'
  },
  {
    value: 'accommodation2_Secure_NASS_accommodation',
    label: 'Secure NASS accommodation',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Accommodation 3 of 3
const stepFourOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'accommodation3_Section_17_paid_for_accommodation_time_limited',
    label: 'Section 17 paid for accommodation (time limited)',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation3_recent_move',
    label: 'Recent move',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Addiction 1 of 2
const stepFiveOptions = [
  {
    value: 'addiction1_Active_addiction_to_alcohol_or_drugs_with_a_child',
    label: 'Active addiction to alcohol or drugs (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'addiction1_Active_addiction_to_alcohol_drugs',
    label: 'Active addiction to alcohol/drugs',
    risk: 'High Complexity'
  },
  {
    value: 'addiction1_Consistent_engagement_with_substance_misuse_services',
    label: 'Consistent engagement with substance misuse services',
    risk: 'Medium Complexity'
  },
  {
    value: 'addiction1_Recovery_no_current_relapse',
    label: 'Recovery- no current relapse',
    risk: 'Low Complexity'
  },
  {
    value: 'addiction1_No_addiction_or_history_of_substance_misuse',
    label: 'No addiction or history of substance misuse',
    risk: 'Independent'
  }
]

// Addiction 2 of 2
const stepSixOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'addiction2_Sporadic_engagement_with_substance_misuse_services',
    label: 'Sporadic engagement with substance misuse services',
    risk: 'High Complexity'
  },
  {
    value: 'addiction2_Relapse',
    label: 'Relapse',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: 'addiction2_In_recovery_for_historic_substance_misuse',
    label: 'In recovery for historic substance misuse',
    risk: 'Independent'
  }
]

// Disability 1 of 4
const stepSevenOptions = [
  {
    value: 'disability1_Unmet_care_needs',
    label: 'Unmet care needs',
    risk: 'Safeguarding'
  },
  {
    value: 'disability1_Significant_physical_disability',
    label: 'Significant physical disability',
    risk: 'High Complexity'
  },
  {
    value: 'disability1_Learning_Disability',
    label: 'Learning Disability',
    risk: 'Medium Complexity'
  },
  {
    value: 'disability1_Unconfirmed_or_suspected_learning_disability',
    label: 'Unconfirmed or suspected learning disability',
    risk: 'Low Complexity'
  },
  {
    value: 'disability1_No_disability',
    label: 'No disability',
    risk: 'Independent'
  }
]

// Disability 2 of 4
const stepEightOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'disability2_Sensory_disability',
    label: 'Sensory disability',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Disability 3 of 4
const stepNineOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'disability3_Child_with_a_disability',
    label: 'Child with a disability',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Disability 4 of 4
const stepTenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'disability4_Physical_Disability',
    label: 'Physical Disability',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Finances 1 of 6
const stepElevenOptions = [
  {
    value: 'finances1_Financial_Exploitation_or_abuse',
    label: 'Financial Exploitation or abuse',
    risk: 'Safeguarding'
  },
  {
    value: 'finances1_Large_arrears_or_debt_to_council',
    label: 'Large arrears or debt to council',
    risk: 'High Complexity'
  },
  {
    value: 'finances1_Section_4_support',
    label: 'Section 4 support',
    risk: 'Medium Complexity'
  },
  {
    value: 'finances1_Section_95_support',
    label: 'Section 95 support',
    risk: 'Low Complexity'
  },
  {
    value: 'finances1_Permanent_job_stable_income',
    label: 'Permanent job/stable income',
    risk: 'Independent'
  }
]

// Finances 2 of 6
const stepTwelveOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'finances2_Large_fine_repayment',
    label: 'Large fine repayment',
    risk: 'High Complexity'
  },
  {
    value: 'finances2_Unstable_or_inconsistent_income_through_employment',
    label: 'Unstable or inconsistent income through employment',
    risk: 'Medium Complexity'
  },
  {
    value: 'finances2_DWP_Benefits_and_HB_able_to_understand_correspondence_and_rules_around_benefits',
    label: 'DWP Benefits and HB + able to understand correspondence and rules around benefits',
    risk: 'Low Complexity'
  },
  {
    value: 'finances2_Able_to_manage_budget_and_live_within_means',
    label: 'Able to manage budget and live within means',
    risk: 'Independent'
  }
]

// Finances 3 of 6
const stepThirteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'finances3_Consistent_poor_budget_management',
    label: 'Consistent poor budget management',
    risk: 'High Complexity'
  },
  {
    value: 'finances3_Risk_of_unemployment',
    label: 'Risk of unemployment',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Finances 4 of 6
const stepFourteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'finances4_New_Universal_Credit_Claim',
    label: 'New Universal Credit Claim',
    risk: 'High Complexity'
  },
  {
    value: 'finances4_DWP_HB_but_struggles_to_understand_rules_or_correspondence',
    label: 'DWP & HB but struggles to understand rules or correspondence',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Finances 5 of 6
const stepFifteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'finances5_Vulnerable_employment',
    label: 'Vulnerable employment',
    risk: 'High Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Finances 6 of 6
const stepSixteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'finances6_Section_17_support',
    label: 'Section 17 support.',
    risk: 'High Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Language 1 of 3
const stepSeventeenOptions = [
  {
    value: 'language1_Language_controlled_by_another',
    label: 'Language controlled by another',
    risk: 'Safeguarding'
  },
  {
    value: 'language1_No_spoken_or_written_English_and_not_accessing_classes',
    label: 'No spoken or written English and not accessing classes',
    risk: 'High Complexity'
  },
  {
    value: 'language1_Illiterate_but_with_spoken_English',
    label: 'Illiterate but with spoken English',
    risk: 'Medium Complexity'
  },
  {
    value: 'language1_English_steadily_improving',
    label: 'English steadily improving',
    risk: 'Low Complexity'
  },
  {
    value: 'language1_Fluent',
    label: 'Fluent',
    risk: 'Independent'
  }
]

// Language 2 of 3
const stepEighteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'language2_Little_spoken_English',
    label: 'Little spoken English',
    risk: 'Medium Complexity'
  },
  {
    value: 'language2_Attending_regular_English_classes',
    label: 'Attending regular English classes',
    risk: 'Low Complexity'
  },
  {
    value: 'language2_Confident_in_English_language',
    label: 'Confident in English language',
    risk: 'Independent'
  }
]

// Language 3 of 3
const stepNineteenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'language3_No_classes_but_struggles_to_understand_complex_language',
    label: 'No classes but struggles to understand complex language',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 1 of 7
const stepTwentyOptions = [
  {
    value: 'legal1_Detained_and_at_risk_of_deportation',
    label: 'Detained and at risk of deportation',
    risk: 'Safeguarding'
  },
  {
    value: 'legal1_Challengeable_negative_CG',
    label: 'Challengeable negative CG',
    risk: 'High Complexity'
  },
  {
    value: 'legal1_Failed_Asylum_seeker_section_4',
    label: 'Failed Asylum seeker (section 4)',
    risk: 'Medium Complexity'
  },
  {
    value: 'legal1_Asylum_Seeker',
    label: 'Asylum Seeker',
    risk: 'Low Complexity'
  },
  {
    value: 'legal1_Refugee_Status_or_British_Citizen',
    label: 'Refugee Status or British Citizen',
    risk: 'Independent'
  }
]

// Legal 2 of 7
const stepTwentyOneOptions = [
  {
    value: 'legal2_In_prison_or_under_arrest',
    label: 'In prison or under arrest',
    risk: 'Safeguarding'
  },
  {
    value: 'legal2_Challenging_No_DL_with_CG',
    label: 'Challenging No DL with +CG',
    risk: 'High Complexity'
  },
  {
    value: 'legal2_Open_Police_investigation',
    label: 'Open Police investigation',
    risk: 'Medium Complexity'
  },
  {
    value: 'legal2_Current_Discretionary_Leave',
    label: 'Current Discretionary Leave',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 3 of 7
const stepTwentyTwoOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'legal3_No_recourse_to_public_funds',
    label: 'No recourse to public funds',
    risk: 'High Complexity'
  },
  {
    value: 'legal3_Open_compensation_claim',
    label: 'Open compensation claim',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 4 of 7
const stepTwentyThreeOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'legal4_Failed_Asylum_seeker_destitute',
    label: 'Failed Asylum seeker (destitute)',
    risk: 'High Complexity'
  },
  {
    value: 'legal4_Criminal_record',
    label: 'Criminal record',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 5 of 7
const stepTwentyFourOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'legal5_Preparing_to_testify_in_court_case',
    label: 'Preparing to testify in court case',
    risk: 'High Complexity'
  },
  {
    value: 'legal5_Family_reunion_process',
    label: 'Family reunion process',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 6 of 7
const stepTwentyFiveOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'legal6_No_access_to_solicitor',
    label: 'No access to solicitor',
    risk: 'High Complexity'
  },
  {
    value: 'legal6_Awaiting_DL',
    label: 'Awaiting DL',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Legal 7 of 7
const stepTwentySixOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'legal7_Imminent_Immigration_deadline',
    label: 'Imminent Immigration deadline',
    risk: 'High Complexity'
  },
  {
    value: 'legal7_Awaiting_BRP_card',
    label: 'Awaiting BRP card',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Mental 1 of 4
const stepTwentySevenOptions = [
  {
    value: 'mental1_Actively_Suicidal',
    label: 'Actively Suicidal',
    risk: 'Safeguarding'
  },
  {
    value: 'mental1_Diagnosed_complex_psychiatric_disorder',
    label: 'Diagnosed complex psychiatric disorder',
    risk: 'High Complexity'
  },
  {
    value: 'mental1_Mental_Health_problems_without_mental_health_support',
    label: 'Mental Health problems without mental health support',
    risk: 'Medium Complexity'
  },
  {
    value: 'mental1_Currently_receiving_mental_health_support',
    label: 'Currently receiving mental health support',
    risk: 'Low Complexity'
  },
  {
    value: 'mental1_Able_to_independently_access_mental_health_services_if_needed',
    label: 'Able to independently access mental health services if needed',
    risk: 'Independent'
  }
]

// Mental 2 of 4
const stepTwentyEightOptions = [
  {
    value: 'mental2_Risk_to_self_others',
    label: 'Risk to self/others',
    risk: 'Safeguarding'
  },
  {
    value: 'mental2_Suicidal_ideation',
    label: 'Suicidal ideation',
    risk: 'High Complexity'
  },
  {
    value: 'mental2_Occasional_self_harming_behaviour',
    label: 'Occasional self-harming behaviour',
    risk: 'Medium Complexity'
  },
  {
    value: 'mental2_Sometimes_struggles_with_low_mood_MH',
    label: 'Sometimes struggles with low mood/MH',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Mental 3 of 4
const stepTwentyNineOptions = [
  {
    value: 'mental3_Unable_to_adequately_care_for_children_due_to_mental_health_problems',
    label: 'Unable to adequately care for children due to mental health problems',
    risk: 'Safeguarding'
  },
  {
    value: 'mental3_Not_taking_prescribed_medication',
    label: 'Not taking prescribed medication',
    risk: 'High Complexity'
  },
  {
    value: 'mental3_Eating_disorder',
    label: 'Eating disorder',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Mental 4 of 4
const stepThirtyOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'mental4_Regular_or_serious_self_harming_behaviour',
    label: 'Regular or serious self-harming behaviour',
    risk: 'High Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Physical 1 of 3
const stepThirtyOneOptions = [
  {
    value: 'physical1_Palliative_Care',
    label: 'Palliative Care',
    risk: 'Safeguarding'
  },
  {
    value: 'physical1_Not_receiving_correct_treatment_for_health_problems',
    label: 'Not receiving correct treatment for health problems',
    risk: 'High Complexity'
  },
  {
    value: 'physical1_Chronic_physical_health_problem',
    label: 'Chronic physical health problem',
    risk: 'Medium Complexity'
  },
  {
    value: 'physical1_Needs_support_to_attend_health_appointments',
    label: 'Needs support to attend health appointments',
    risk: 'Low Complexity'
  },
  {
    value: 'physical1_Able_to_independently_access_health_appointments_when_needed',
    label: 'Able to independently access health appointments when needed',
    risk: 'Independent'
  }
]

// Physical 2 of 3
const stepThirtyTwoOptions = [
  {
    value: 'physical2_Significant_unmet_care_needs',
    label: 'Significant unmet care needs',
    risk: 'Safeguarding'
  },
  {
    value: 'physical2_Struggling_to_access_appropriate_services',
    label: 'Struggling to access appropriate services',
    risk: 'High Complexity'
  },
  {
    value: 'physical2_Currently_undergoing_consultant_treatment',
    label: 'Currently undergoing consultant treatment',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Physical 3 of 3
const stepThirtyThreeOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: null,
    label: '',
    risk: 'High Complexity'
  },
  {
    value: 'physical3_Pregnancy',
    label: 'Pregnancy',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Relationship 1 of 5
const stepThirtyFourOptions = [
  {
    value: 'relationship1_Current_Active_Domestic_Violence_relationship_with_a_child',
    label: 'Current/Active Domestic Violence relationship (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship1_Caring_for_a_disabled_child',
    label: 'Caring for a disabled child',
    risk: 'High Complexity'
  },
  {
    value: 'relationship1_Unsafe_risky_relationships_with_family',
    label: 'Unsafe/risky relationships with family',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship1_Accessing_community_groups',
    label: 'Accessing community groups',
    risk: 'Low Complexity'
  },
  {
    value: 'relationship1_Ability_to_make_friends_and_know_healthy_from_unhealthy_relationships',
    label: 'Ability to make friends and know healthy from unhealthy relationships',
    risk: 'Independent'
  }
]

// Relationship 2 of 5
const stepThirtyFiveOptions = [
  {
    value: 'relationship2_Still_in_contact_involved_with_traffickers',
    label: 'Still in contact/involved with traffickers',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship2_Caring_for_a_child_who_has_been_through_trauma',
    label: 'Caring for a child who has been through trauma',
    risk: 'High Complexity'
  },
  {
    value: 'relationship2_Potentially_exploitative_relationships',
    label: 'Potentially exploitative relationships',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship2_Learning_to_differentiate_healthy_from_unhealthy_friendships_and_relationship',
    label: 'Learning to differentiate healthy from unhealthy friendships and relationship',
    risk: 'Low Complexity'
  },
  {
    value: 'relationship2_Has_friends_and_community',
    label: 'Has friends and community',
    risk: 'Independent'
  }
]

// Relationship 3 of 5
const stepThirtySixOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship3_Recently_left_DV_relationship',
    label: 'Recently left DV relationship',
    risk: 'High Complexity'
  },
  {
    value: 'relationship3_Children_overseas_or_family_reunion',
    label: 'Children overseas or family reunion',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship3_A_few_close_friends',
    label: 'A few close friends',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Relationship 4 of 5
const stepThirtySevenOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship4_Disengaged_from_caseworker_and_community_group',
    label: 'Disengaged from caseworker and community group',
    risk: 'High Complexity'
  },
  {
    value: 'relationship4_Socially_isolated',
    label: 'Socially isolated',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// Relationship 5 of 5
const stepThirtyEightOptions = [
  {
    value: null,
    label: '',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship5_Parenting_issues',
    label: 'Parenting issues',
    risk: 'High Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Medium Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Low Complexity'
  },
  {
    value: null,
    label: '',
    risk: 'Independent'
  }
]

// const submitUpdatedRisk = async (values) => {
//   console.log(values)

//   let responses = submissionData.responses

//   responses.push(
//     {
//       questionKey: 'newRiskLevel',
//       questionText: 'New Risk Level',
//       response: values.newRiskLevel
//     }
//   )

//   responses.push(
//     {
//       questionKey: 'newRiskLevelReason',
//       questionText: 'New Risk Level Reason',
//       response: values.newRiskLevelReason
//     }
//   )



//   // const submissionUpdated = await FormService.updateSubmission(apiToken, submissionId, data)

// }

const StepOne = () => {
  const { valid, setValid } = useContext(RiskContext)
  const size = useContext(ResponsiveContext)

  return (
    <>
      <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      >
        {stepOneInputs.map((input, index) => (
          <Box width={{ max: 'medium' }} key={index}>
            {input(setValid)}
          </Box>
        ))}
      </Grid>
      {/* {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )} */}
    </>
  )
}

const DisplayStep = (props) => {
  const { valid, setValid, BuildGridRow } = useContext(RiskContext)
  const size = useContext(ResponsiveContext)

  return (
    <>
      {props.input.map((input, index) => (
        <BuildGridRow key={index} row={props.input[index]} section={props.section} subsection={props.section + (index + 1)} />
      ))}
    </>
  )
}

const createFinalGrid = () => {
  const boxGrid = []
  let index = 0
  let tip = ''
  for (const section in stepAnswers) {
    for (const subSection in stepAnswers[section]) {
      tip = <Box direction='column' background='white'><Text size='small' weight='bold'>{section.toUpperCase()}</Text><Text size='small'>{capitalize(subSection)}</Text><Text size='small'>{stepAnswers[section][subSection]?.risk?.risk}</Text></Box>
      // Skip riskScore
      if (subSection !== 'riskScore') {
        boxGrid.push(<Button plain tip={{ content: tip }}><Box background={riskColorLookup[stepAnswers[section][subSection]?.risk?.risk]} border height='20px' key={index++} width='20px' /></Button>)
      }
    }
  }
  return boxGrid
}

const GetOtherNotes = () => {
  const { formValues } = useContext(RiskContext)
  const noteLabels = [
    {
      section: 'Accommodation',
      subSection: 'Accommodation',
      name: 'accommodationOtherNotes'
    },
    {
      section: 'Addiction',
      subSection: 'Addiction',
      name: 'addictionOtherNotes'
    },
    {
      section: 'Disability',
      subSection: 'Disability',
      name: 'disabilityOtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances',
      name: 'financesbudgetingOtherNotes'
    },
    {
      section: 'Language',
      subSection: 'Language',
      name: 'languageOtherNotes'
    },
    {
      section: 'Legal',
      subSection: 'Legal',
      name: 'legalOtherNotes'
    },
    {
      section: 'Mental',
      subSection: 'Mental',
      name: 'mentalOtherNotes'
    },
    {
      section: 'Physical',
      subSection: 'Physical',
      name: 'physicalOtherNotes'
    },
    {
      section: 'Relationship',
      subSection: 'Relationship',
      name: 'relationshipOtherNotes'
    }
  ]

  const toDisplay = noteLabels.map((label, index) => {
    if (formValues[label.name]) {
      return (
        <Box direction='column' key={index}>
          <Text color='brand' margin={{ top: 'medium', bottom: 'small' }} weight='bold'>{label.section}</Text>
          <Text>{formValues[label.name]}</Text>
        </Box>)
    }
  })

  return toDisplay
}

const StepFinal = () => {
  const { displayRiskChange, setDisplayRiskChange, formValues, riskScores, submissionData, submissionId, history } = useContext(RiskContext)

  const submitUpdatedRisk = async (values) => {
    const updatedSubmissionData = submissionData

    updatedSubmissionData.responses.push(
      {
        questionKey: 'newRiskLevel',
        questionText: 'New Risk Level',
        response: values.newRiskLevel
      }
    )

    updatedSubmissionData.responses.push(
      {
        questionKey: 'newRiskLevelReason',
        questionText: 'New Risk Level Reason',
        response: values.newRiskLevelReason
      }
    )

    updatedSubmissionData.status = 'Published'

    const submissionUpdated = await FormService.updateSubmission(apiToken, submissionId, updatedSubmissionData)
    if (submissionUpdated.error) {
      NotificationService.error(submissionUpdated.error)
    } else {
      history.push('/dashboard')
    }
  }
  return (
    <Box direction='column'>
      <Text size='xsmall'>Overall Risk</Text>
      <Text size='large' weight='bold'>{formValues.firstName} {formValues.lastName}</Text>

      <Text weight='bold'>Latest Risk Matrix</Text>
      <Box direction='row' wrap>
        {createFinalGrid()}
      </Box>

      <Text margin={{ top: 'medium' }} weight='bold'>Notes</Text>
      {GetOtherNotes()}
      {displayRiskChange &&
        <Layer
          position='center'
        >
          <Box
            align='start'
            background='white'
            justify='between'
            margin='large'
            round='small'
          >
            <Text weight='bold'>Calculated Risk</Text>

            <Box
              direction='row'
              margin={{ bottom: 'medium' }}
            >
              <FontAwesome style={{ color: riskColorLookup[riskLabels[riskScores.overall]] }} icon={['fa', 'circle']} />
              <Text color={riskColorLookup[riskLabels[riskScores.overall]]} margin={{ left: 'small' }} size='small'>{riskLabels[riskScores.overall]}</Text>
            </Box>

            <Form
              // onChange={nextValue => {
              //   setLocalUserDetails(nextValue)
              // }}
              onSubmit={({ value: nextValue }) => {
                submitUpdatedRisk(nextValue)
              }}
            >
              <FormField
                label='Change Risk Level'
                name='newRiskLevel'
              >
                <Select
                  name='newRiskLevel'
                  options={riskLabels}
                />
              </FormField>

              <FormField
                label='Reason'
                name='newRiskLevelReason'
              >
                <TextArea
                  name='newRiskLevelReason'
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='Cancel' onClick={() => setDisplayRiskChange(undefined)} secondary />
                <Button type='submit' label='Save' primary />
              </Box>

            </Form>
          </Box>
        </Layer>}
    </Box>
  )
}

const sections = [
  {
    section: 'Client Details'
  },
  {
    section: 'Accommodation',
    value: 'accommodation'
  },
  {
    section: 'Addiction',
    value: 'addiction'
  },
  {
    section: 'Disability',
    value: 'disability'
  },
  {
    section: 'Finances/Budgeting',
    value: 'finances'
  },
  {
    section: 'Language',
    value: 'language'
  },
  {
    section: 'Legal',
    value: 'legal'
  },
  {
    section: 'Mental Health',
    value: 'mental'
  },
  {
    section: 'Physical Health',
    value: 'physical'
  },
  {
    section: 'Relationships',
    value: 'relationship'
  }
]

var steps = []

steps.push({
  description: 'Complete the following to continue',
  inputs: <StepOne />,
  section: 'Client Details',
  title: 'Client Details',
  question: 'Question 1 of 1',
  riskLevel: 'Missing Answers'
})

steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepTwoOptions, stepThreeOptions, stepFourOptions]} section='accommodation' />,
  section: 'Accommodation',
  title: 'Accommodation',
  question: 'Question 1 of 3',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepFiveOptions, stepSixOptions]} section='addiction' />,
  section: 'Addiction',
  question: 'Question 1 of 2',
  title: 'Addiction',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepSevenOptions, stepEightOptions, stepNineOptions, stepTenOptions]} section='disability' />,
  section: 'Disability',
  question: 'Question 1 of 4',
  title: 'Disability',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepElevenOptions, stepTwelveOptions, stepThirteenOptions, stepFourteenOptions, stepFifteenOptions, stepSixteenOptions]} section='finances' />,
  section: 'Finances/Budgeting',
  question: 'Question 1 of 6',
  title: 'Finances/Budgeting',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepSeventeenOptions, stepEighteenOptions, stepNineteenOptions]} section='language' />,
  section: 'Language',
  question: 'Question 1 of 3',
  title: 'Language',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepTwentyOptions, stepTwentyOneOptions, stepTwentyTwoOptions, stepTwentyThreeOptions, stepTwentyFourOptions, stepTwentyFiveOptions, stepTwentySixOptions]} section='legal' />,
  section: 'Legal',
  question: 'Question 1 of 7',
  title: 'Legal',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepTwentySevenOptions, stepTwentyEightOptions, stepTwentyNineOptions, stepThirtyOptions]} section='mental' />,
  section: 'Mental',
  question: 'Question 1 of 4',
  title: 'Mental',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepThirtyOneOptions, stepThirtyTwoOptions, stepThirtyThreeOptions]} section='physical' />,
  section: 'Physical',
  question: 'Question 1 of 3',
  title: 'Physical',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Select options to continue',
  inputs: <DisplayStep input={[stepThirtyFourOptions, stepThirtyFiveOptions, stepThirtySixOptions, stepThirtySevenOptions, stepThirtyEightOptions]} section='relationship' />,
  section: 'Relationship',
  question: 'Question 1 of 5',
  title: 'Relationship',
  riskLevel: '',
  selected: ''
})
steps.push({
  description: 'Complete the following to continue',
  inputs: <StepFinal />,
  section: 'FINAL',
  question: 'Question 1 of 1',
  title: 'Final page',
  riskLevel: ''
})

function RiskMatrixWizard () {
  const history = useHistory()

  // Hold the submission data which will be appended
  // to and saved if the risk level is changed
  const [submissionData, setSubmissionData] = useState({})

  // Holds the submission id of the form when first saved
  const [submissionId, setSubmissionId] = useState(null)

  const [gridOptions, setGridOptions] = useState({
    accommodation1: '',
    accommodation2: '',
    accommodation3: '',
    addiction1: '',
    addiction2: '',
    disability1: '',
    disability2: '',
    disability3: '',
    disability4: '',
    finances1: '',
    finances2: '',
    finances3: '',
    finances4: '',
    finances5: '',
    finances6: '',
    language1: '',
    language2: '',
    language3: '',
    legal1: '',
    legal2: '',
    legal3: '',
    legal4: '',
    legal5: '',
    legal6: '',
    legal7: '',
    mental1: '',
    mental2: '',
    mental3: '',
    mental4: '',
    physical1: '',
    physical2: '',
    physical3: '',
    relationship1: '',
    relationship2: '',
    relationship3: '',
    relationship4: '',
    relationship5: ''
  })

//  useEffect(() => {
//     console.log('My Grid Options', gridOptions)
//   }, [gridOptions])

  const gridOptionClicked = (value, options, section, subsection) => {
    var newOptions = {}
    // newOptions[subsection] = value
    newOptions[value] = !gridOptions[value]

    setGridOptions((previousValues) => ({
      ...previousValues,
      [subsection]: value,
      ...newOptions
    }))

    updateAnswer(value, options, section, subsection)
  }

  const BuildGridRow = (props) => {
    const rowList = []

    props.row.forEach(element => {
      if (element.value) {
        rowList.push(
          <Box align='center' border background={gridOptions[element.value] ? riskColorLookup[element.risk] : ''} justify='center' onClick={() => gridOptionClicked(element.value, props.row, props.section, props.subsection)}>
            <Text size='small' textAlign='center'>
              {element.label}
            </Text>
          </Box>
        )
      } else {
        rowList.push(
          <Box align='center' border justify='center' />
        )
      }
    })

    return rowList
  }

  const size = useContext(ResponsiveContext)

  //   const theme = useContext(ThemeContext)
  const [activeIndex, setActiveIndex] = useState(0)
  // for readability, this is used to display numeric value of step on screen,
  // such as step 1 of 3. it will always be one more than the active array index
  const [activeStep, setActiveStep] = useState(activeIndex + 1)

  // store form values in state so they persist
  // when user goes back a step
  const [formValues, setFormValues] = useState(defaultFormValues)

  // controls state of cancel layer
  const [open, setOpen] = useState(false)

  // tracks validation results for the current step
  const [valid, setValid] = useState(true)

  // ref allows us to access the wizard container and ensure scroll position
  // is at the top as user advances between steps. useEffect is triggered
  // when the active step changes.
  const riskRef = useRef()

  const [riskScores, setRiskScores] = useState({
    accommodation: -1,
    addiction: -1,
    disability: -1,
    finances: -1,
    language: -1,
    legal: -1,
    mental: -1,
    physical: -1,
    relationship: -1,
    overall: -1
  })

  const [displayRiskChange, setDisplayRiskChange] = useState(false)

  useEffect(() => {
    setActiveStep(activeIndex + 1)
  }, [activeIndex])

  const id = 'simple-wizard'

  const params = useParams()

  const [userId, setUserId] = useState(params.userId)
  useEffect(() => {
    setUserId(params.userId)
  }, [params.userId])

  // scroll to top of step when step changes
  useEffect(() => {
    const container = riskRef.current
    const header = document.querySelector(`#${id}`)
    container.scrollTop = -header.getBoundingClientRect().bottom
  }, [activeIndex, open])

  useEffect(() => {
    let unmounted = false

    const getUserDetails = async (userId) => {
      const userDetails = await UserService.getUser(apiToken, userId, {
        type: 'client',
        fields: 'id,reference,firstName,lastName,email,dob,ethnicity,gender'
      })

      console.log(userDetails)
      if (!unmounted) {
        setFormValues(prevState => ({
          ...prevState,
          // reference: userDetails?.data?.[0]?.reference,
          firstName: userDetails?.data?.[0]?.firstName,
          lastName: userDetails?.data?.[0]?.lastName,
          // email: userDetails?.data?.[0]?.email,
          // dob: userDetails?.data?.[0]?.dob ? moment(userDetails?.data?.[0]?.dob).format('YYYY-MM-DD') : null,
          // ethnicity: userDetails?.data?.[0]?.ethnicity || '',
          gender: userDetails?.data?.[0]?.gender
          // age: moment().diff(userDetails?.data?.[0]?.dob, 'years')
        // console.log(userDetails.data)
        }))
      }
    }

    if (userId !== 'new') {
      if (hasPermission('formAccessRiskMatrix')) {
        getUserDetails(userId)
        setActiveIndex(1)
      } else {
        history.push('/dashboard')
      }
    }

    return () => { unmounted = true }
  }, [])

  // const numberColumns = 2
  //   const width = getWidth(numberColumns, theme, size)

  const updateRiskScores = (riskMatrixScore) => {
    // If there's an error it's likely because we have submitted
    // the client details so there will be no score yet
    if (!riskMatrixScore.error) {
      const riskMatrixArray = Object.entries(riskMatrixScore.summary)

      for (let [riskSection, riskLevel] of riskMatrixArray) {
        // Convert riskSection to match what we have here
        switch (riskSection) {
          case 'Finance':
            riskSection = 'finances'
            break
          case 'MentalHealth':
            riskSection = 'Mental'
            break
          case 'PhysicalHealth':
            riskSection = 'Physical'
            break
          case 'Relationships':
            riskSection = 'Relationship'
            break
        }

        // Check if there is a risk level
        if (Object.keys(riskLevel).length > 0) {
          setRiskScores(previousValues => ({
            ...previousValues,
            [riskSection.toLowerCase()]: riskLevel.level
          }))
        }
      }
    }
  }

  const onSubmit = async (values) => {
    console.log(stepAnswers)
    console.log(values)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each step answer
    // for (var primaryKey in stepAnswers) {
    //   for (var subKey in stepAnswers[primaryKey]) {
    //     if (subKey !== 'riskScore') {
    //       if (stepAnswers[primaryKey][subKey].risk !== undefined) {
    //         item = {
    //           questionKey: stepAnswers[primaryKey][subKey].risk.value,
    //           questionText: stepAnswers[primaryKey][subKey].risk.label,
    //           response: stepAnswers[primaryKey][subKey].risk.risk
    //         }
    //         submitArray.push(item)
    //       }
    //     }
    //   }
    // }

    // Loop through each grid options
    if (Object.keys(gridOptions)?.length) {
      for (var key in gridOptions) {
        var newValue = false
        if (gridOptions[key] && gridOptions[key] !== '') {
          newValue = gridOptions[key]
        }
        item = {
          questionKey: key,
          questionText: `${newValue}`,
          response: `${newValue}`
        }
        submitArray.push(item)
      }
    }

    // Now add the personal details
    const personalDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      age: values.age,
      gender: values.gender,
      nationality: values.nationality
    }

    submitArray.push({
      questionKey: 'personalDetails',
      questionText: 'Personal Details',
      response: JSON.stringify(personalDetails)
    })

    // And any notes
    const riskNotes = {
      accommodationOtherNotes: values.accommodationOtherNotes,
      addictionOtherNotes: values.addictionOtherNotes,
      disabilityOtherNotes: values.disabilityOtherNotes,
      financesbudgetingOtherNotes: values.financesbudgetingOtherNotes,
      languageOtherNotes: values.languageOtherNotes,
      legalOtherNotes: values.legalOtherNotes,
      mentalOtherNotes: values.mentalOtherNotes,
      physicalOtherNotes: values.physicalOtherNotes,
      relationshipOtherNotes: values.relationshipOtherNotes
    }

    submitArray.push({
      questionKey: 'riskNotes',
      questionText: 'Risk Notes',
      response: JSON.stringify(riskNotes)
    })

    // Build output data
    // Change to Published if on last page
    let submissionStatus = ''
    if (activeIndex === steps.length - 1) {
      submissionStatus = 'Published'
    } else {
      submissionStatus = 'Draft'
    }
    // const draft = 'Draft'

    const data = {
      user: activeUser.id,
      organisation: activeOrg.id,
      status: submissionStatus,
      type: 'riskmatrix',
      reference: '',
      // notify: JSON.stringify(notifyArray),
      responses: submitArray
    }

    if (userId !== 'new') {
      data.subject = userId
    }
    // console.log(data)

    // If not saved before, create new submission
    if (submissionId === null) {
      const submissionAdded = await FormService.makeSubmissions(apiToken, data)
      // console.log(submissionAdded)
      if (submissionAdded.error) {
        NotificationService.error(submissionAdded.error)
      } else {
        const submissionId = submissionAdded.data.id

        setSubmissionId(submissionId)
      }
    } else {
      // Save submission data for risk level change submission
      setSubmissionData(data)

      // Edit existing submission
      const submissionUpdated = await FormService.updateSubmission(apiToken, submissionId, data)
      if (submissionUpdated?.error) {
        NotificationService.error(submissionUpdated.error)
      } else {

        await getRiskMatrixScore(submissionId)

        // Display if on last page
        if (activeIndex === steps.length - 1) {
          setDisplayRiskChange(true)
          // NotificationService.info('Risk Matrix added')
        }
        // history.push('/clients')
      }
    }
  }

  const getRiskMatrixScore = async (submissionId) => {
    const riskMatrixScore = await RiskMatrixService.riskMatrix(apiToken, submissionId)
    updateRiskScores(riskMatrixScore)
  }

  useEffect(() => {
    if (submissionId) {
      getRiskMatrixScore(submissionId)
    }
  }, [submissionId])

  useEffect(() => {
    const getResponses = async () => {
      const thisParams = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: params.formId
      }

      const responses = await FormService.getResponses(apiToken, thisParams, where)

      const savedResponses = {}
      responses.data.forEach(element => {
        let thisValue = element.response

        if (element.questionKey === 'clientName') {
          thisValue = parseInt(element.response)
        }
        savedResponses[element.questionKey] = thisValue
      })
      setGridOptions(savedResponses)
      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (params.formId !== 'new') {
      getResponses()
    }

  }, [])


  useEffect(() => {
    const getLastResponses = async () => {
      const lastSubmission = await FormService.getSubmissions(apiToken, {
        fields: 'id,author,user',
        limit: 1,
        sort: 'id DESc'
      }, {
        type: 'riskmatrix',
        status: 'Published',
        deleted: false,
        subject: userId
      })

      if (!lastSubmission?.data?.[0]?.id) {
        console.log('TRACE - no submission')
        return
      }

      const thisParams = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: lastSubmission.data[0].id
      }

      const responses = await FormService.getResponses(apiToken, thisParams, where)

      const savedResponses = {}
      responses.data.forEach(element => {
        let thisValue = element.response

        if (element.questionKey === 'clientName') {
          thisValue = parseInt(element.response)
        }
        savedResponses[element.questionKey] = thisValue
      })
      setGridOptions(savedResponses)
      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (params.formId === 'new') {
      getLastResponses()
    }

  }, [])

  return (
    <RiskContext.Provider
      value={{
        activeIndex,
        id,
        defaultFormValues,
        setActiveIndex,
        activeStep,
        setActiveStep,
        valid,
        ref: riskRef,
        riskColorLookup,
        setValid,
        steps,
        formValues,
        setFormValues,
        stepAnswers,
        overallRisk,
        riskScores,
        displayRiskChange,
        setDisplayRiskChange,
        BuildGridRow,
        submissionData,
        submissionId,
        history
        // wizardTitle: 'Wizard Title',
        // width
      }}
    >
      <Box width='xlarge'>
        <Box background='white' direction='column' elevation='small' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='column' pad={{ horizontal: 'medium' }}>
            <Text margin={{ top: 'small' }} size='xlarge'>Risk Form: {formValues.firstName ? formValues.firstName + ' ' + formValues.lastName : 'New User'}</Text>
            <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
          </Box>
        </Box>

        <Box background='white' direction='column' elevation='small' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box pad='small'>
            <Box direction='row' width={{ max: 'medium' }}>
              <Box
                alignSelf='center'
                background={riskColorLookup[riskLabels[riskScores.overall]] || 'brand'}
                height='20px'
                round
                width='20px'
              />
              <Box direction='column'>
                <Text margin={{ left: 'small' }} size='large' weight='bold'>Overall Risk</Text>
                <Text color={riskColorLookup[riskLabels[riskScores.overall]]} margin={{ left: 'small' }} size='xsmall'>{riskLabels[riskScores.overall] || ''}</Text>
              </Box>
            </Box>
            <Divider color='primary' margin={{ top: 'small', bottom: 'small' }} />

            <Grid columns={{ count: 2, size: 'auto' }}>
              {sections.map((section, index) => (
                <Box direction='row' width={{ max: 'medium' }} key={index}>
                  <Box
                    alignSelf='center'
                    background={riskColorLookup[riskLabels[riskScores[section.value]]] || 'brand'}
                    height='20px'
                    round
                    width='20px'
                  />
                  <Box direction='column'>
                    <Text margin={{ left: 'small' }}>{section.section}</Text>
                    <Text color={riskColorLookup[riskLabels[riskScores[section.value]]]} margin={{ left: 'small' }} size='xsmall'>{riskLabels[riskScores[section.value]] || ''}</Text>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box direction='row-responsive' gap='small'>
          <Box background='white' elevation='small' direction='column' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small' width={size === 'small' ? '100%' : '66%'}>

            {/* <WizardHeader setOpen={setOpen} /> */}
            <StepContent
              formValues={formValues}
              // onSubmit={({ value }) => console.log('onSubmit:', value)}
              onSubmit={({ value }) => onSubmit(value)}
            />
            <StepFooter />
          </Box>
        </Box>
      </Box>
      {/* {open && <CancellationLayer onSetOpen={setOpen} />} */}
    </RiskContext.Provider>
  )
};

export default RiskMatrixWizard
