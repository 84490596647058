import React, { useState, useEffect } from 'react'

import moment from 'moment'

import AuditTrailService from '../../services/AuditTrailService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Text from '../../components/simple/text/Text'
import Divider from '../../components/simple/divider/Divider'

function StaffEditAuditTrail (props) {
  const [auditTrailDetails, setAuditTrailDetails] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')


  const [staff, setStaff] = useState([])

  const getUsers = async () => {
    const params = {
      fields: 'id,firstName,lastName,reference',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff'
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      NotificationService.error(users.error)
    } else if (users?.data) {
      var mappedUsers = {}
      for (const user of users.data) {
        let id = parseInt(user.id)
        mappedUsers[id] = {
          id,
          name: user.firstName + ' ' + user.lastName
        }
      }
      setStaff(mappedUsers)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  const getAuditTrail = async (userId) => {
    const where = {
      actionedBy: userId,
      organisation: activeOrg.id,
      site: activeSite.id
    }
    const auditTrail = await AuditTrailService.get(apiToken, where)
    if (auditTrail?.error) {
      NotificationService.error(auditTrail.error)
    } else if (auditTrail?.data) {
      setAuditTrailDetails(auditTrail.data)
    }
  }

  useEffect(() => {
    if (props.userId !== 'new') {
      getAuditTrail(props.userId)
    }
  }, [])

  return (
    <>
      <Divider color='primary' />
      <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
        {/* <Text size='large' weight='bold'>Action Log</Text> */}
        {auditTrailDetails.length > 0
          ? auditTrailDetails?.slice(0).reverse().map((item) => (
            <Box direction='column' key={item.id} margin={{ left: '20px', top: '20px' }}>
              <Text size='xsmall'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
              <Text size='large' weight='bold'>{item.description || '- Unknown Action -'}</Text>
              {item.actionedBy && <Text size='small'>By: {staff?.[item.actionedBy]?.name || 'Unknown'}</Text>}
            </Box>)
          )
          : <Text>No Audit Trail details available</Text>}
      </Box>
    </>
  )
}

export default StaffEditAuditTrail
