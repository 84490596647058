import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import moment from 'moment'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import FormService from '../../services/FormService'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Divider from '../../components/simple/divider/Divider'
import Text from '../../components/simple/text/Text'
import AuditTrailService from '../../services/AuditTrailService'

function Audit(props) {
  const urlParams = useParams()

  const [loading, setLoading] = useState(false)

  // Notes list on display ie. could be filtered
  const [actions, setActions] = useState([])

  // All Notes
  const [users, setUsers] = useState([])
  const [questions, setQuestions] = useState(props.questions)

  // Search bar input
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const [staff, setStaff] = useState([])

  const getUsers = async () => {
    const params = {
      fields: 'id,firstName,lastName,reference',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff'
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      NotificationService.error(users.error)
    } else if (users?.data) {
      var mappedUsers = {}
      for (const user of users.data) {
        let id = parseInt(user.id)
        mappedUsers[id] = {
          id,
          name: user.firstName + ' ' + user.lastName
        }
      }
      setStaff(mappedUsers)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    const getAuditTrail = async (userId) => {
      const where = {
        subject: userId,
        organisation: activeOrg.id,
        site: activeSite.id,
      }

      const params = {
        sort: 'id DESC',
        limit: 200
      }

      const auditTrail = await AuditTrailService.get(apiToken, where, params)
      if (!auditTrail.error) {
        setActions(auditTrail.data)
      }
    }

    if (urlParams.userid !== 'new') {
      getAuditTrail(urlParams.userid)
    }
  }, [])

  return (
    <Box gridArea='main' direction='column' gap='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        {actions?.length > 0
          ? (
            <Box>
              {actions?.length > 0
                ? actions?.map((item) => (
                  <Box direction='column' key={item.id} margin={{ left: '20px', top: '20px' }}>
                    <Text size='xsmall'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
                    <Text size='large' weight='bold'>{item.description || '- Unknown Action -'}</Text>
                    {item.actionedBy && <Text size='small'>By: {staff?.[item.actionedBy]?.name || 'Unknown'}</Text>}
                  </Box>)
                )
                : <Text>No Results available</Text>}
            </Box>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default Audit
