import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import RadioButtonGroup from '../../../../components/simple/input/RadioButtonGroup'
import RatingButtonGroup from '../../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import ClientNames from '../../shared/ClientNames'

import NotifyStaff from '../../shared/NotifyStaff'

// Services
import UserService from '../../../../services/UserService'
import LocalStorageService from '../../../../services/LocalStorageService'

function StepOneAnxiety () {
  const { valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const [staff, setStaff] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const doingWorkOptions = [
    {
      label: 'Not difficult at all',
      value: 'notDifficult'
    },
    {
      label: 'Somewhat difficult',
      value: 'somewhatDifficult'
    },
    {
      label: 'Very difficult',
      value: 'veryDifficult'
    },
    {
      label: 'Extremely difficult',
      value: 'extremelyDifficult'
    }
  ]

  const stepOneInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <ClientNames />

          <FormField
            label='Feeling nervous, anxious or on edge'
            htmlFor='feelingNervous'
            name='feelingNervous'
          >
            <RatingButtonGroup
              clear='n/a'
              id='feelingNervous'
              name='feelingNervous'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Not being able to stop or control worrying'
            htmlFor='controlWorrying'
            name='controlWorrying'
          >
            <RatingButtonGroup
              clear='n/a'
              id='controlWorrying'
              name='controlWorrying'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Worrying too much about different things'
            htmlFor='worrying'
            name='worrying'
          >
            <RatingButtonGroup
              clear='n/a'
              id='worrying'
              name='worrying'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Trouble relaxing'
            htmlFor='notRelaxing'
            name='notRelaxing'
          >
            <RatingButtonGroup
              clear='n/a'
              id='notRelaxing'
              name='notRelaxing'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Being so restless that it is hard to sit still'
            htmlFor='restless'
            name='restless'
          >
            <RatingButtonGroup
              clear='n/a'
              id='restless'
              name='restless'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Becoming easily annoyed or irritable'
            htmlFor='irritable'
            name='irritable'
          >
            <RatingButtonGroup
              clear='n/a'
              id='irritable'
              name='irritable'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Feeling afraid as if something awful might happen'
            htmlFor='feelingAfraid'
            name='feelingAfraid'
          >
            <RatingButtonGroup
              clear='n/a'
              id='feelingAfraid'
              name='feelingAfraid'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?'
            htmlFor='doWork'
            name='doWork'
          >
            <RadioButtonGroup
              direction='row-responsive'
              labelKey='label'
              name='doWork'
              options={doingWorkOptions}
              valueKey='value'
            />
          </FormField>

          <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

          <NotifyStaff />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  useEffect(() => {
    let unmounted = false

    // Get Staff for this organisation
    const getStaff = async () => {
      var params = {
        fields: 'id,firstName,lastName',
        limit: 1000,
        type: 'user',
        orgId: activeOrg.id,
        siteId: activeSite?.id || null
      }

      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: (data?.firstName || '') + ' ' + (data?.lastName || '')
            }))
            setStaff(mappedUsers)
          }
        }
      }
    }

    getStaff()

    return () => { unmounted = true }
  }, [])

  return (
    <>
      <Box width='100%'>
      {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepOneInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
      {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepOneAnxiety
