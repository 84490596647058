import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import moment from 'moment'

import Responsive from '../../utils/Responsive'
import { hasAnyPermissionIn, hasPermission } from '../../utils/permissions'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UploadService from '../../services/UploadService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import { Dropzone } from '../../components/simple/input/Dropzone/Dropzone'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import H2 from '../../components/simple/heading/H2.js'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

function Documents (props) {
  // Set staff or client for permission checks
  const [userType, setUserType] = useState('')

  // Document list on display ie. could be filtered
  const [documents, setDocuments] = useState([])

  // Are we viewing archived documents ?
  const [viewArchive, setViewArchive] = useState(false)

  // All documents
  const [documentsAll, setDocumentsAll] = useState([])

  const [documentToArchiveRestore, setDocumentToArchiveRestore] = useState(null)
  const [showArchiveRestoreOptions, showArchiveRestore] = useState(false)

  // Search bar input
  const [filter, setFilter] = useState('')
  const [filesToUpload, setFilesToUpload] = useState([])

  // Sort option selected
  const [sortBy, setSortBy] = useState('dateDesc')

  //   const [questions, setQuestions] = useState(props.questions)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')

  // Sorting options
  const sortOptions = [
    {
      label: 'Date ASC',
      value: 'dateAsc'
    },
    {
      label: 'Date DESC',
      value: 'dateDesc'
    },
    {
      label: 'Name ASC',
      value: 'nameAsc'
    },
    {
      label: 'Name DESC',
      value: 'nameDesc'
    }
  ]

  const params = useParams()
  let userId = null

  // Check for Service User Document
  if (params.userid !== undefined) {
    userId = params.userid
  }

  // Check for Staff Document
  if (params.staffid !== undefined) {
    userId = params.staffid
  }

  // Filter has been changed
  const filterDocuments = (input) => {
    const filtered = documentsAll.filter(document => {
      return document.title.toLowerCase().includes(input.toLowerCase())
    })

    setFilter(input)
    setDocuments(filtered)
  }

  // Prompt user to delete/restore document
  const showDocumentOptions = (document) => {
    setDocumentToArchiveRestore(document)
    showArchiveRestore(true)
  }

  // Archive/Restore document
  const archiveRestoreDocument = async () => {
    if (viewArchive) {
      // Restore document
      const response = await MediaService.update(apiToken, documentToArchiveRestore.id, { deleted: false })
      if (response?.data[0]) {
        // And from state to force a refresh
        // const newDocuments = documents.filter((item) => item.id !== documentToArchiveRestore.id)
        // setDocuments(newDocuments)
        getDocuments()
        NotificationService.success(`${documentToArchiveRestore.title} restored`)
      } else {
        NotificationService.error(response.error)
      }
    } else {
      // Delete from server
      const response = await MediaService.delete(apiToken, documentToArchiveRestore)
      if (response.data.success === 'true') {
        // And from state to force a refresh
        const newDocuments = documents.filter((item) => item.id !== documentToArchiveRestore.id)
        setDocuments(newDocuments)
        NotificationService.success(`${documentToArchiveRestore.title} deleted`)
      } else {
        NotificationService.error(response.error)
      }
    }
    showArchiveRestore(false)
  }

  // View documents
  const viewDocuments = async () => {
    setViewArchive(!viewArchive)
  }

  useEffect(() => {
    (async () => {
      await getDocuments()
    })()
  }, [viewArchive])

  // Submit User Details
  const submitForm = () => {
    // console.log(filesToUpload)
    // props.submitForm(filesToUpload)
    // if (filesToUpload.length === 0) {
    //   // nextPage()
    // } else {
    filesToUpload.forEach(async (document) => {
      const params = {
        orgId: activeOrganisation.id
      }

      // strip data tag at the beginning of the base64 string
      document.preview = document.preview.replace(`data:${document.type};base64,`, '')

      let data = {
        fileName: document.path,
        contentType: document.type,
        file: document.preview
      }

      let response = await UploadService.file(apiToken, params, data)
      if (response?.error) {
        NotificationService.error(response.error)
      } else {
        data = {
          organisation: activeOrganisation.id,
          title: document.name,
          type: 'document',
          documentUrl: response.data.baseUrl,
          owner: userId
        }

        response = await MediaService.create(apiToken, params, data)

        if (response?.error) {
          NotificationService.error(response.error)
        } else {
          NotificationService.success('Documents successfully saved')

          // Refresh document list
          const documents = await getDocuments()
        }
      }
    })
    // }
  }

  const addFiles = async (files) => {
    setFilesToUpload([...filesToUpload, ...files])
  }

  const deleteFiles = (files) => {
    setFilesToUpload(files)
  }

  const getDocuments = async () => {
    const params = {
      fields: 'id,title,documentUrl,createdAt',
      limit: 1000,
      orgId: activeOrganisation.id,
      where: {
        organisation: activeOrganisation.id,
        owner: userId,
        type: 'document',
        deleted: viewArchive
      }
    }

    const documents = await MediaService.get(apiToken, params)
    console.log(documents)
    if (documents?.error) {
      // NotificationService.error(documents.error)
    } else {
      setDocuments(documents.data)
      setDocumentsAll(documents.data)
    }
  }

  useEffect(() => {
    if (documents) {
      const currentDocuments = documents

      switch (sortBy) {
        case 'dateDesc':
          currentDocuments.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
          break
        case 'dateAsc':
          currentDocuments.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
          break
        case 'nameDesc':
          currentDocuments.sort((a, b) => (a.title < b.title) ? 1 : -1)
          break
        case 'nameAsc':
          currentDocuments.sort((a, b) => (a.title > b.title) ? 1 : -1)
          break
        default:
          break
      }

      setDocuments([...currentDocuments])
    }
  }, [sortBy])

  useEffect(() => {
    let unmounted = false

    if (params.staffid === undefined) {
      setUserType('client')
    } else {
      setUserType('staff')
    }

    getDocuments()

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>{viewArchive ? 'Archived' : null} Documents</H2>
          {showArchiveRestoreOptions &&
            <NotificationLayer button1Text='Yes' button1Click={archiveRestoreDocument} button2Text='No' button2Click={() => { showArchiveRestore(false) }}>Are you sure you want to {viewArchive ? 'restore' : 'archive'} the document {documentToArchiveRestore.title}?</NotificationLayer>}
          <Button label={<FontAwesome icon={['fal', 'question-circle']} />} plain tip='Documents' />
          <Anchor onClick={() => { viewDocuments() }}>
            <Text size='xsmall' weight='light' color='darkGrey'>
              {viewArchive ? (hasPermission(`${userType}DocumentsView`) ? 'View Documents' : '') : (hasPermission(`${userType}DocumentsArchive`) ? 'View Archive' : '')}
            </Text>
          </Anchor>
        </Box>

        <>
          <Responsive
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['1/2', '1/2'],
              large: ['1/2', '1/2'],
              xlarge: ['1/2', '1/2']
            }}
            gap='small'
          >
            <Box direction='row-responsive' gap='small'>
              <TextInput
                icon={<FontAwesome icon={['fal', 'search']} />}
                onChange={event => filterDocuments(event.target.value)}
                reverse
                value={filter}
              />
              <Select
                id='select'
                labelKey='label'
                name='select'
                onChange={
                  ({ value: option }) => setSortBy(option)
                }
                options={sortOptions}
                value={sortBy}
                valueKey={{ key: 'value', reduce: true }}
              />
            </Box>
          </Responsive>

          <Form
            onChange={nextValue => {
              console.log(nextValue)
              props.setUserDetails(nextValue)
            }}
            onSubmit={({ value: nextValue }) => {
              submitForm(nextValue)
            }}
            value={props.userDetails}
          >
            <Responsive
              rows={['auto']}
              columns={{
                small: ['auto'],
                medium: ['1/2', '1/2'],
                large: ['1/2', '1/2'],
                xlarge: ['1/2', '1/2']
              }}
              gap='medium'
            >
              <Box>
                {documents?.length > 0
                  ? documents?.map((item) => (
                    <Box direction='column' key={item.id} margin={{ left: '0', top: '20px' }}>
                      <Box direction='column'>
                        <Text size='xsmall'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
                        <Text size='medium' weight='bold'>
                          <Anchor href={item.documentUrl}>
                            {item.title} {params.staffid} {userType}
                          </Anchor>
                        </Text>

                        {hasAnyPermissionIn([`${userType}DocumentArchive`, `${userType}DocumentRestore`]) &&
                          <Anchor onClick={() => { showDocumentOptions(item) }}>
                            <Text size='xsmall' weight='light' color='darkGrey'>
                              {viewArchive ? (hasPermission(`${userType}DocumentRestore`) ? 'Restore' : '') : (hasPermission(`${userType}DocumentArchive`) ? 'Archive' : '')}
                            </Text>
                          </Anchor>}
                      </Box>
                    </Box>)
                  )
                  : <Text margin={{ top: 'small' }}>No Documents available</Text>}
              </Box>

              <Box direction='column' pad='small' border={[{ size: 'small', color: '#eee', style: 'solid', side: 'left' }]}>
                <Text weight='bold' margin={{ bottom: 'small' }}>Upload New Document</Text>
                <Dropzone
                  multiple
                  showPreview
                  showFileSize
                  onAddFiles={addFiles}
                  onDeleteFiles={deleteFiles}
                />

                {/* <FileUpload
                    addFile={addFile}
                /> */}
              </Box>
            </Responsive>

            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => props.previousPage()} secondary />
              <Button type='submit' label='Upload' primary />
            </Box>
          </Form>
        </>
      </Box>
    </Box>
  )
}

export default Documents
