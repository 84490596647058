/* eslint no-debugger: "warn" */

// dependencies
import React from 'react'
import LocalStorageService from './LocalStorageService'
//
// const serverRoot = 'http://localhost:1337'
// const serverRoot = 'https://dev-api-snowdrop.ontheappt.cloud'
const serverRoot = 'https://stage-api-snowdrop.ontheappt.cloud'
// const serverRoot = 'https://prod-api-snowdrop.ontheappt.cloud'

const currentOrg = LocalStorageService.get('activeOrg')
const currentSite = LocalStorageService.get('activeSite')
const apiToken = LocalStorageService.get('apiToken')

const appVersion = '1.3.0'

const showPreviewBanner = true
const previewBannerMessage = 'UAT ENVIRONMENT - This is a preview site for testing only. All data entered will be deleted.'

class Api extends React.Component {

  login = async (username, password) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var options = {
      method: 'POST',
      headers
    }

    const requestUrl = '/api/auth/login'

    options.body = JSON.stringify({
      username,
      password
    })

    const fullUrl = `${serverRoot}${requestUrl}`

    const request = new Request(fullUrl, options)
    const response = await fetch(request)

    return response.json()
  }

  // resetPasswordRequest = async (organisationId, email) => {
  //   const headers = new Headers()
  //   headers.append('Content-Type', 'application/json')
  //   var options = {
  //     method: 'POST',
  //     headers
  //   }
  verifyAccount = async (username, password, verificationCode) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var options = {
      method: 'POST',
      headers
    }

    const requestUrl = '/api/auth/login'

    options.body = JSON.stringify({
      username,
      password,
      verificationCode
    })

    const fullUrl = `${serverRoot}${requestUrl}`

    const request = new Request(fullUrl, options)
    const response = await fetch(request)

    return response.json()
  }

  resetPassword = async (organisationId, email) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var options = {
      method: 'POST',
      headers
    }

    const requestUrl = '/send-reset-link'
    let urlParams = `?email=${encodeURIComponent(email)}`
    if (organisationId) {
      urlParams = `${urlParams}&organisationId=${encodeURIComponent(organisationId)}`
    }

    const fullUrl = `${serverRoot}${requestUrl}${urlParams}`

    const request = new Request(fullUrl, options)
    const response = await fetch(request)

    return response.json()
  }

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  get = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error
    const options = {
      method: 'GET'
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || undefined
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    console.log('ApiService')
    console.log(url)
    console.log(paramsStr)

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options)
    const response = await fetch(request)
    return response.json()
  }

  openDocument = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error
    const options = {
      method: 'GET'
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || undefined
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    // console.log('ApiService')
    // console.log(url)
    // console.log(paramsStr)

    window.open(`${serverRoot}${url}${paramsStr}`)
  }

  openInTab = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error
    const options = {
      method: 'GET'
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || undefined
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    // console.log('ApiService')
    // console.log(url)
    // console.log(paramsStr)

    window.open(`${serverRoot}${url}${paramsStr}`, '_blank')
  }

  getConnection = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error
    const options = {
      method: 'GET'
    }

    const request = new Request(`${serverRoot}${url}`, options)
    const response = await fetch(request)
    return response.text()
  }

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  post = async (apiToken, url, params = {}, options = {}) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    var optionsParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(options)
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId && currentOrg?.id) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId && currentSite?.id) {
      params.siteId = currentSite?.id || null
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    return returnResponse
  }

  // Before login no apiToken is available so use this instead
  // which is identical to Post apart from that
  postNoToken = async (url, params = {}, options = {}) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    var optionsParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(options)
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        console.log('key', key)
        console.log('value', value)
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    return returnResponse
  }

  put = async (apiToken, url, params = {}, options = {}) => {
    // check for an api key, otherwise throw an error

    if (!apiToken) {
      throw Error('Missing API Token')
    }

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    var optionsParams = {
      method: 'PUT',
      headers,
      body: JSON.stringify(options)
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || null
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    return returnResponse
  }

  // Before login no apiToken is available so use this instead
  // which is identical to Put apart from that
  putNoToken = async (url, params = {}, options = {}) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    var optionsParams = {
      method: 'PUT',
      headers,
      body: JSON.stringify(options)
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        console.log('key', key)
        console.log('value', value)
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    return returnResponse
  }

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  sendDelete = async (apiToken, url, params = {}, options = {}) => {
    // check for an api key, otherwise throw an error

    if (!apiToken) {
      throw Error('Missing API Token')
    }

    const body = {
      method: 'DELETE',
      body: JSON.stringify(options)
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.orgId) {
      params.orgId = currentOrg.id
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || null
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    console.log('ApiService')
    console.log(url)
    console.log(paramsStr)

    const request = new Request(`${serverRoot}${url}${paramsStr}`, body)
    const response = await fetch(request)
    return response.json()
  }

  registerPublic = async (data) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var options = {
      method: 'POST',
      headers
    }

    const requestUrl = '/api/auth/signup'

    options.body = JSON.stringify({
      data
    })

    const fullUrl = `${serverRoot}${requestUrl}`

    const request = new Request(fullUrl, options)
    const response = await fetch(request)

    return response.json()
  }

  showPreviewBanner = showPreviewBanner
  previewBannerMessage = previewBannerMessage
}

// export default Api
export default new Api()
