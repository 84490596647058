import React, { useEffect, useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Appt Components
import FormField from '../../../components/simple/formField/FormField.js'
import Select from '../../../components/simple/input/Select'

function ClientNames (props) {
  const [loading, setLoading] = useState(false)

  const [userValues, setUserValues] = useState([])

  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  useEffect(() => {
    let unmounted = false

    // Get Clients for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'client'
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()

    // setLocalFormDetails(previousValues => ({
    //   ...previousValues,
    //   clientName: parseInt(props.clientId)
    // }))
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  return (
    <>
      {/* Client Name */}
      <FormField
        label='Name of Client'
        name='clientName'
        required
      >
        <Select
          name='clientName'
          labelKey='name'
          options={userValues}
          valueKey={{ key: 'id', reduce: true }}
        />
      </FormField>
    </>
  )
}
export default ClientNames
