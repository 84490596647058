import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import moment from 'moment'

import Responsive from '../../utils/Responsive'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import FormService from '../../services/FormService'

import { hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

function Forms(props) {
  const history = useHistory()

  const urlParams = useParams()

  const [loading, setLoading] = useState(false)

  // Notes list on display ie. could be filtered
  const [notes, setNotes] = useState([])

  // All Notes
  const [notesAll, setNotesAll] = useState([])

  // New note detail being added
  const [newNote, setNewNote] = useState([])

  const [users, setUsers] = useState([])
  const [questions, setQuestions] = useState(props.questions)

  // Search bar input
  const [filter, setFilter] = useState('')

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const editForm = (item) => {
    history.push({ pathname: `/form/${item.type}/${item.id}/edit`, clientid: urlParams.userid })
  }

  const viewForm = (item) => {
    var pathname = `/form/${item.type}/${item.id}/view`
    history.push({ pathname, clientid: urlParams.userid })
  }

  // Filter has been changed
  const filterNotes = (input) => {
    const filtered = notesAll.filter(note => {
      return note.description.toLowerCase().includes(input.toLowerCase())
    })

    setFilter(input)
    setNotes(filtered)
  }

  // Submit Note Details
  const submitNote = async () => {
    const params = {
      orgId: activeOrganisation.id
    }

    const data = {
      description: newNote.noteDescription,
      organisation: activeOrganisation.id,
      owner: newNote.noteAssignTo,
      subType: newNote.noteType,
      title: 'Note',
      type: 'note'
    }

    const response = await MediaService.create(apiToken, params, data)

    if (response?.error) {
      NotificationService.error(response.error)
    }
  }

  const goFQForm = () => {
    history.push(`/fqform/${urlParams.userid}/edit`)
  }

  const comingSoon = () => {
    alert('Coming Soon')
  }

  useEffect(() => {
    let unmounted = false

    // const getUsersAndNotes = async () => {
    //   let params = {
    //     fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
    //     limit: 1000,
    //     orgId: activeOrganisation.id,
    //     siteId: activeSite?.id || null,
    //     type: 'staff'
    //   }

    //   const users = await UserService.getUsers(apiToken, params)
    //   if (users?.error) {
    //     NotificationService.error(users.error)
    //   } else {
    //     const mappedUsers = users.data.map((data, index) => ({
    //       id: data.id,
    //       name: data.firstName + ' ' + data.lastName
    //     }))

    //     setUsers(mappedUsers)

    //     // Now get the Notes
    //     params = {
    //       fields: 'id,title,createdAt,subType,description,owner',
    //       limit: 10,
    //       orgId: activeOrganisation.id,
    //       sort: 'id DESC',
    //       where: {
    //         organisation: activeOrganisation.id,
    //         published: true,
    //         owner: urlParams.userid,
    //         type: 'note',
    //         deleted: false
    //       }
    //     }

    //     const notes = await MediaService.get(apiToken, params)
    //     console.log(notes)
    //     if (notes?.error) {
    //       // NotificationService.error(notes.error)
    //     } else {
    //       // users?.data.find(user => user.id === item.owner)
    //       let owner = ''
    //       notes.data.forEach(note => {
    //         owner = users?.data.find(user => user.id === note.owner)

    //         if (urlParams.staffid !== undefined) {
    //           note.ownerName = owner.firstName + ' ' + owner.lastName
    //         }
    //       })
    //       setNotes(notes.data)
    //       setNotesAll(notes.data)
    //     }
    //   }
    // }

    const getUsersAndForms = async () => {
      const params = {
        fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
        limit: 1000,
        orgId: activeOrganisation.id,
        siteId: activeSite?.id || null,
        type: 'staff'
      }

      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          const mappedUsers = users.data.map((data, index) => ({
            id: data.id,
            name: data.firstName + ' ' + data.lastName
          }))

          setUsers(mappedUsers)

          const params = {
            fields: 'id,user,organisation,reference,type,status,createdAt',
            limit: 1000,
            sort: 'createdAt DESC',
            orgId: activeOrganisation.id,
            siteId: activeSite.id
            // siteId: null // forms aren't being saved with the site id, for now just return site null
          }

          // let where = {
          //   // status: 'Published',
          //   // type: 'incidentreport',
          //   organisation: activeOrganisation.id
          //   // user: urlParams.userid
          // }
          const submissions = await FormService.getSubmissions(apiToken, params, {
            organisationId: activeOrganisation.id,
            subject: urlParams.userid,
            // type: 'incidentreport',
            status: 'Published'
          })

          console.log(submissions)
          if (submissions?.error) {
            // NotificationService.error(submissions.error)
          } else {
            let owner = ''
            submissions.data.forEach(note => {
              owner = users?.data.find(user => user.id === note.user)

              if (urlParams.userid && owner) {
                note.ownerName = `${owner.firstName || ''} ${owner.lastName || ''}`
              }
            })
            setNotes(submissions.data)
            setNotesAll(submissions.data)
          }
        }
      }
    }

    getUsersAndForms()

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' direction='column' gap='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        {/* <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>*** IN PROGRESS ***</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='Notes' />
        </Box> */}
        {props.userDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setNewNote(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitNote(nextValue)
              }}
              value={newNote}
            >
              <Responsive
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['1/2', '1/2'],
                  large: ['1/2', '1/2'],
                  xlarge: ['1/2', '1/2']
                }}
                gap='small'
              >
                <Box>
                  <Box direction='row-responsive' gap='small' margin={{ top: 'medium' }}>
                    <TextInput
                      icon={<FontAwesome icon={['fal', 'search']} />}
                      onChange={event => filterNotes(event.target.value)}
                      reverse
                      value={filter}
                    />
                  </Box>

                  {notes?.length > 0
                    ? notes?.map((item) => (
                      <Box direction='column' key={item.id} margin={{ left: '20px', top: '20px' }}>
                        <Text size='xsmall'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
                        <Text size='large' weight='bold'>{item.status}: {item.type}</Text>
                        {item.ownerName && <Text size='small'>By: {item.ownerName}</Text>}
                        <Text size='xsmall'>
                          <Anchor onClick={() => { viewForm(item) }} size='xsmall' weight={100} color='darkGrey'>
                            View
                          </Anchor>
                        </Text>
                      </Box>)
                    )
                    : <Text>No Forms available</Text>}
                </Box>

                <Box direction='column' gap='medium'>
                  <Text weight='bold'>New Form</Text>

                  <Box direction='row-responsive' gap='small'>
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessClientCarePlan']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/clientcareplanform/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Client Care Plan' />}
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessMonitoring']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/monitoringform/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Monitoring' />}
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessExitCasework']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/exitcasework/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Exit (Casework)' />}
                  </Box>
                  <Box direction='row-responsive' gap='small'>
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessGAD7']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/gad7form/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='GAD7' />}
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessPHQ9']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/phq9form/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='PHQ9' />}
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessExitCounselling']) &&
                      <Button onClick={() => { history.push({ pathname: '/form/exitcounselling/new/edit', clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Exit (Counselling)' />}
                  </Box>
                  <Box direction='row-responsive' gap='small'>
                    {hasAnyPermissionIn(['formAccessAll', 'formAccessRiskMatrix']) &&
                      <Button onClick={() => { history.push({ pathname: `/form/riskmatrix/new/edit/${urlParams.userid}`, clientid: urlParams.userid }) }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Risk Matrix' />}
                  </Box>
                </Box>
              </Responsive>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default Forms
